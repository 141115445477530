<template>
  <!-- 运营工具 - 数据点击统计 -->
  <div class="pageContol dataClickStatistics">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">数据点击统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 检索条件 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="终端类型" class="searchboxItem ci-full">
              <span class="itemLabel">终端类型:</span>
              <el-select
                v-model="seachData.terminalCode"
                placeholder="请选择终端类型"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in terminalCodeData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="模块标识" class="searchboxItem ci-full">
              <span class="itemLabel">模块标识:</span>
              <el-select
                v-model="seachData.moduleCode"
                placeholder="请选择模块标识"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in moduleCodeData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="位置标识" class="searchboxItem ci-full">
              <span class="itemLabel">位置标识:</span>
              <el-select
                v-model="seachData.positionCode"
                placeholder="请选择位置标识"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in positionCodeData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="行为内容" class="searchboxItem ci-full">
              <span class="itemLabel">行为内容:</span>
              <el-input
                v-model="seachData.contentName"
                clearable
                placeholder="请输入行为内容"
                size="small"
              ></el-input>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="采集日期" class="searchboxItem ci-full">
              <span class="itemLabel">采集日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="seachData.times"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df" style="padding: 0 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="exportList()">导出</el-button>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column type="index" label="序号" align="center" min-width="50" fixed></el-table-column>
              <el-table-column
                label="终端类型"
                align="left"
                show-overflow-tooltip
                prop="terminalCode"
                min-width="160"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.terminalCode == 'PC' || scope.row.terminalCode == 'PC_MGT'">电脑端</span>
                  <span v-else-if="scope.row.terminalCode == 'ANDROID'">安卓端</span>
                  <span v-else-if="scope.row.terminalCode == 'IOS'">ios端</span>
                  <span v-else-if="scope.row.terminalCode == 'WX'">微信小程序端</span>
                  <span v-else>- -</span>
                </template>
              </el-table-column>
              <el-table-column
                label="模块标识"
                align="left"
                show-overflow-tooltip
                prop="moduleCode"
                width="160"
              >
                <template slot-scope="scope">
                  {{
                  $setDictionary(
                  "BURIED_POINT_MODULE_CODE",
                  scope.row.moduleCode
                  )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="位置标识"
                align="left"
                show-overflow-tooltip
                prop="positionCode"
                width="160"
              >
                <template slot-scope="scope">
                  {{
                  $setDictionary(
                  "BURIED_POINT_POSITION_CODE",
                  scope.row.positionCode
                  )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="事件"
                align="left"
                prop="eventCode"
                show-overflow-tooltip
                min-width="150"
              ></el-table-column>
              <el-table-column
                label="行为内容"
                align="left"
                prop="contentName"
                show-overflow-tooltip
                min-width="200"
              ></el-table-column>
              <el-table-column
                label="数量"
                align="left"
                prop="collectNum"
                show-overflow-tooltip
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="采集日期"
                align="left"
                show-overflow-tooltip
                prop="collectDate"
                width="180"
              >
                <template slot-scope="scope">{{ scope.row.collectDate }}</template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; // 引入表格无数据提示 - 组件
import PageNum from "@/components/PageNum.vue"; // 引入分页 - 组件
import List from "@/mixins/List"; // 引入列表 - 混入
import { mapGetters } from "vuex";
export default {
  name: "dataClickStatistics",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      seachData: {
        terminalCode: "", // 终端类型
        moduleCode: "", // 模块标识
        positionCode: "", // 位置标识
        contentName: "", // 行为内容
        times: "" // 采集时间
      },
      // 终端类型 - 下拉数据
      terminalCodeData: [
        {
          value: "PC",
          label: "电脑端"
        },
        {
          value: "ANDROID",
          label: "安卓端"
        },
        {
          value: "IOS",
          label: "ios端"
        },
        {
          value: "WX",
          label: "微信小程序端"
        }
      ],
      // 模块标识 - 下拉数据
      moduleCodeData: [],
      // 位置标识 - 下拉数据
      positionCodeData: []
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {
    this.getDictionaries();
  },
  methods: {
    // 获取 - 对应字典
    getDictionaries() {
      // 获取 - 模块标识字典
      const list_1 = this.$setDictionary("BURIED_POINT_MODULE_CODE", "list");
      // 获取 - 位置标识字典
      const list_2 = this.$setDictionary("BURIED_POINT_POSITION_CODE", "list");
      for (const key in list_1) {
        this.moduleCodeData.push({
          value: key,
          label: list_1[key]
        });
      }
      for (const key in list_2) {
        this.positionCodeData.push({
          value: key,
          label: list_2[key]
        });
      }
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.seachData.terminalCode) {
        params.terminalCode = this.seachData.terminalCode;
      }
      if (this.seachData.moduleCode) {
        params.moduleCode = this.seachData.moduleCode;
      }
      if (this.seachData.positionCode) {
        params.positionCode = this.seachData.positionCode;
      }
      if (this.seachData.contentName) {
        params.contentName = this.seachData.contentName;
      }
      if (this.seachData.times && this.seachData.times.length) {
        params.startTime = this.seachData.times[0];
        params.endTime = this.seachData.times[1];
      }
      this.doFetch({
        url: "/sys/buried/date/pageList",
        params,
        pageNum
      });
    },
    // 导出- 列表数据
    exportList(){
      const params = {};
      if (this.seachData.terminalCode) {
        params.terminalCode = this.seachData.terminalCode;
      }
      if (this.seachData.moduleCode) {
        params.moduleCode = this.seachData.moduleCode;
      }
      if (this.seachData.positionCode) {
        params.positionCode = this.seachData.positionCode;
      }
      if (this.seachData.contentName) {
        params.contentName = this.seachData.contentName;
      }
      if (this.seachData.times && this.seachData.times.length) {
        params.startTime = this.seachData.times[0];
        params.endTime = this.seachData.times[1];
      }
      this.$post("/sys/buried/date/list/export", params).then((res) => {
        if (res.status == "0") {
          let list = [];
          list.push(res.data);
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      })
    },
  }
};
</script>

<style lang="less" scoped>
</style>
