import { render, staticRenderFns } from "./dataClickStatistics.vue?vue&type=template&id=3452a443&scoped=true"
import script from "./dataClickStatistics.vue?vue&type=script&lang=js"
export * from "./dataClickStatistics.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3452a443",
  null
  
)

export default component.exports